import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { PublicationSidebar } from '../components/PublicationSidebar'
import { Comments } from '../components/Comments'
import config from '../utils/config'
import { getFormattedDate, formatAuthors } from '../utils/helpers'

export default function PublicationTemplate({ data }) {
  const publication = data.markdownRemark
  const { tags, categories, title, date, thumbnail, 
    comments_off, doi, authors, journal } =
    publication.frontmatter

  const formattedDate = getFormattedDate(date)
  const authorsbold = formatAuthors(authors)

  return (
    <div>
      <Helmet title={`${publication.frontmatter.title} | ${config.siteTitle}`} />
      <SEO postPath={publication.fields.slug} postNode={publication} postSEO />

      <div className="container">
        <div className="grid">
          <div className="article-content">
            <div className="publication-header medium width">
              <h1>{title}</h1>
            </div>

            <div className="publication-card">
                <ul>
                  <li>Published {formattedDate}</li>
                  <li dangerouslySetInnerHTML={{ __html: authorsbold }}/>
                  <li>{journal}</li>
                  <li>
                    <a
                      href={doi}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={doi}
                    >
                      {doi}
                    </a>
                  </li>
                </ul>
              </div>



            <section className="segment small">
              <div
                id={publication.fields.slug}
                className="post-content"
                dangerouslySetInnerHTML={{ __html: publication.html }}
              />
            </section>

            <section id="comments" className="segment">
              <div className="card single">
                <h3>Newsletter</h3>
                <p className="text-medium">
                  If you liked this post, sign up to get updates in your email
                  when I write something new! No spam ever.
                </p>
                <a
                  href="https://birkenbihl.substack.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button highlighted"
                >
                  Subscribe to the Newsletter
                </a>
              </div>
            </section>

            {!comments_off && (
              <section id="comments" className="segment comments">
                <h3>Comments</h3>
                <Comments />
              </section>
            )}
          </div>

          <PublicationSidebar
            date={date}
            tags={tags}
            categories={categories}
            thumbnail={thumbnail}
            doi={doi}
            authors={authors}
            journal={journal}
          />
        </div>
      </div>
    </div>
  )
}

PublicationTemplate.Layout = Layout

export const pageQuery = graphql`
  query PublicationBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        categories
        description
        comments_off
        doi
        authors
        journal
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
